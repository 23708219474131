interface IVGSCollect {
  serviceType: string;
  vaultId: string;
  environment: string;
  version: string;
  url: string;
}

export const VGSCollect: IVGSCollect[] = [
  {
    serviceType: 'P2P',
    vaultId: 'tntegu6bzpv',
    environment: 'live',
    version: '2.19.0',
    url: '/api/transferp2p/token',
  },
  {
    serviceType: 'SALE',
    vaultId: 'tntvhzogepj',
    environment: 'live',
    version: '2.21.0',
    url: '/api/ecom/processlinkedcard',
  },
];

export const environment = {
  production: true,
  apiUrl: 'https://psp-api.paynet.md',
  p2pURL: 'https://p2p.paynet.md',
  oplatamdURL: 'https://oplata.md',
  paynetmdURL: 'https://paynet.md',
};
